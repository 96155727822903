$(document).ready(function($) {

	let isFirstPageChange = true;
	let currentFrame = 0;

	let touchStart = 0;
	let scrollTimeout = null;
	let scrollTimeoutLength = 1500;
	let scrollBlocked = false;

	const contentFrames = $('.content-frame');

	const wrapper = $('#wrapper');
	const flatlines = $('#flatlines');
	const navbar = $('#navbar');
	const footer = $('#footer');
	const contactButton = $('#contact-button');
	const frameNav = $('#frame-nav');
	const frameNavItems = $('.frame-nav__item');

	function setWrapper() {
		$(wrapper).removeClass('layout-default--dark layout-default--light');
		$(wrapper).addClass('layout-default--' + ((currentFrame % 2 === 1) ? 'light' : 'dark'));
	}

	function setFlatlines() {
		$(flatlines).removeClass('flatlines--frame--0 flatlines--frame--1 flatlines--frame--2 flatlines--frame--3');
		$(flatlines).addClass('flatlines--frame--' + (currentFrame % 4));
	}

	function setFrames() {
		$(contentFrames).removeClass('content-frame--active content-frame--is-before');
		for (let i = 0; i < currentFrame; i++) {
			$(contentFrames[i]).addClass('content-frame--is-before');
		}
		$(contentFrames[currentFrame]).addClass('content-frame--active');
	}

	function setFrameNav() {
		$(frameNavItems).removeClass('frame-nav__item--active');
		$(frameNavItems[currentFrame]).addClass('frame-nav__item--active');
	}

	function setFrame() {
		setWrapper();
		setFlatlines();
		setFrames();
		setFrameNav();
	}

	function slideDown() {
		if (currentFrame < contentFrames.length - 1) {
			scrollBlocked = true;
			currentFrame = currentFrame + 1;
			setFrame();
			scrollTimeout = setTimeout(() => {
				scrollBlocked = false;
			}, scrollTimeoutLength);
		}
	}

	function slideUp() {
		if (currentFrame > 0) {
			scrollBlocked = true;
			currentFrame = currentFrame - 1;
			setFrame();
			scrollTimeout = setTimeout(() => {
				scrollBlocked = false;
			}, scrollTimeoutLength);
		}
	}

	function goToFrame() {
		if (!scrollBlocked) {
			scrollBlocked = true;
			currentFrame = parseInt($(this).data('number'));
			setFrame();
			scrollTimeout = setTimeout(() => {
				scrollBlocked = false;
			}, scrollTimeoutLength);
		}
	}

	function onScroll(event) {
		if (!$(wrapper).hasClass('layout-default--menu-is-open')) {
			const delta = Math.sign(event.deltaY);

			if (!scrollBlocked && ((delta > 0 && currentFrame < contentFrames.length - 1) || (delta < 0 && currentFrame > 0))) {

				scrollBlocked = true;

				if (delta > 0 && currentFrame < contentFrames.length - 1) {
					slideDown();
				}
				else if (delta < 0 && currentFrame > 0) {
					slideUp();
				}

			}
		}
	}

	function onTouchstart(event) {
		if (!$(wrapper).hasClass('layout-default--menu-is-open')) {
			touchStart = event.touches[0].clientY;
		}
	}

	function onTouchend(event) {
		if (!$(wrapper).hasClass('layout-default--menu-is-open')) {
			if (!scrollBlocked) {

				const touchEnd = event.changedTouches[0].clientY;

				if (touchStart > touchEnd + 5) {
					slideDown();
				} else if (touchStart < touchEnd - 5) {
					slideUp();
				}

			}

			touchStart = 0;
		}
	}

	function addScrollEvents() {
		window.addEventListener('wheel', onScroll);
		window.addEventListener('touchstart', onTouchstart);
		window.addEventListener('touchend', onTouchend);
		$(frameNavItems).click(goToFrame);
	}

	function removeScrollEvents() {
		window.removeEventListener('wheel', onScroll);
		window.removeEventListener('touchstart', onTouchstart);
		window.removeEventListener('touchend', onTouchend);
		$(frameNavItems).unbind();
	}

	function initFrames() {
		if ($(contentFrames).length > 0) {
			$(contentFrames).addClass('content-frame--mounted');
			$(contentFrames[0]).addClass('content-frame--active');
			$(frameNav).addClass('frame-nav--mounted');
			$(frameNavItems[0]).addClass('frame-nav__item--active');
			addScrollEvents();
		}
	}

	function mount() {
		setTimeout(() => {
			$(navbar).addClass('navbar--mounted');
			$(footer).addClass('footer--mounted');
			$(flatlines).addClass('flatlines--mounted flatlines--frame--0');
			$(contactButton).addClass('contact-button--mounted');
		}, 500);
	}

	mount();

	if ($('#page-frames').length > 0) {
		if(isFirstPageChange) {
			setTimeout(() => {
				initFrames();
			}, 1000);
			isFirstPageChange = false;
		}
		else {
			setTimeout(() => {
				initFrames();
			}, 500);
		}
	}

});
