import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import './offcanvas';
import './frames';

import './watchForHover';

import './browserSync';

