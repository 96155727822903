$(document).ready(function($) {

	const wrapper = $('#wrapper');
	const mobileMenu = $('#mobile-menu');
	const offcanvasButton = $('#offcanvas-button');

	function toggleMobileMenu() {
		if ($(mobileMenu).hasClass('mobile-menu--active')) {
			$(mobileMenu).removeClass('mobile-menu--active');
			$(offcanvasButton).removeClass('offcanvas-button--active');
			$(wrapper).removeClass('layout-default--menu-is-open');
		} else {
			$(mobileMenu).addClass('mobile-menu--active');
			$(offcanvasButton).addClass('offcanvas-button--active');
			$(wrapper).addClass('layout-default--menu-is-open');
		}
	}

	$(offcanvasButton).click(toggleMobileMenu);

});
